$border:		#f1f1f2;
$green:			#c8d4cd;
$greybg:		#f2f2f2;
$greyblue:		#798697;
$white:			#FFF;
$grey:			#262223;
@dark:	

body {
	//direction: rtl;
	//text-align:right;
	font-family: 'Montserrat', sans-serif;
	font-weight:400;
}

.align-center {
	text-align:center;
}

.form-group {
	margin-bottom:15px;
}

.button {
	display: block;
    padding: 15px 30px;
    font-weight: 700;
    font-size: 20px;
    position: relative;
    z-index: 10;
    margin: 15px 0 0 0;
    color: $white;
    border-radius: 5px;
    background: $greyblue;
    border:0;
    width:100%;
    outline:none;
    text-transform:uppercase;
    letter-spacing:2px;

    &:focus,
    &:active {
    	outline:none;
    	
    }
}

.navbar-expand-lg .navbar-nav {
	li {
		a.nav-link {
			text-transform:uppercase;
			letter-spacing:2px;
			font-weight:700;
			font-size:0.9rem;
			padding-left:15px;
			padding-right:15px;
		}
	}
}

#signup,
#questions,
#questions-answer {
	background:$greybg ;
	background:$greybg;
	min-height:calc(100vh - 88px);
	padding:50px 0;
	display:flex;
	flex-direction:column;
	justify-content:center;

	.app-icon {
		width:50%;
		float:left;
		text-align: center;
		margin-top:30px;
		margin-bottom:15px;

		img {
			max-width:100px;
			height:auto;
		}
	}

	h1 {
		text-align:center;
		font-weight:700;
		margin-bottom:30px;

		span {
			display:block;
			font-size:1.0rem;
			text-transform:uppercase;

			letter-spacing:2px;
			font-weight:600;
			margin-bottom:10px;
		}
	}

	select,
	textarea,
	input[type=text],
	input[type=email] {
		font-family:arial;
		font-size:14px;
		background:$greybg;
		border:0;
		padding:15px;
		height:auto;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&:focus,
		&:active {
			border:0;
			outline:none;
			box-shadow:none;
		}
	}

	select {
		background:$greybg url(./images/chevron.png) top 22px right 20px no-repeat;
		background-size:15px;
	}

	label {
		display:none;
	}

	.form {
		background:white;
		border-radius:5px;
		padding:50px 35px 35px 35px;
		box-sizing:border-box;
		-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.10);
		-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.10);
		box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.10);
	}

}

#questions-answer,
#questions {
	label {
		display:inline-block;
		font-weight:700;
	}
}

#signup .container,
#questions-answer .container {
	max-width:600px;
}

#questions {
	.container {

		.form {
			padding:0;

			.questions {
				padding:0;
			}

			.question-area {
				padding:50px;
			}
		}

		.list-group-item {
			border-left:0;
			border-top:0;
			background:none;
			border-color:$border;
			cursor:pointer;

			p {
				margin:0;
			}

			&.active {
				color:$grey;
				border-right:0;
				border-color:$border;
				font-weight:700;
			}



			&:first-child,
			&:last-child {
				border-radius:0;
			}
		}
	}
}

.list {
  text-align: left;
  //max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  width:100%;
  margin: auto;
}

.navbar-light {
	background:white !important;
	border-bottom:0;
	height:88px;

	.navbar-brand {
		margin:0;
	}

	#logo {
		max-width:250px;
		height:auto;
	}
}

#banner {
	background:$green;
	background-size:cover;
	padding:80px 0;
	text-align:center;

	h1 {
		color:$grey;
		font-weight:700;
	}
}

.register-button .row {
	border-top:1px solid $border;
}

#countries {
	
}

#countries,
#telecoms {
	padding:30px 0;

	h3 {
		margin-bottom:30px;
	}

}

.countryName,
.telecomName {
	text-align:center;
	font-weight:bold;
	margin-bottom:15px;
}

.country,
.telecom {
	border:10px solid white;
	border-radius:50%;
	display:block;
	width:140px;
	padding:10px;
	position:relative;
	height:140px;
	margin:0 auto;
	margin-bottom:15px;
	box-sizing:border-box;
	cursor:pointer;

	input {
		width:100%;
		height:100%;
		opacity:0;
		position:relative;
		z-index:10;
		outline:none;
		cursor:pointer;
	}

	&.iraq {
		background:url(./images/iraq.png) center center no-repeat;
		background-size:100%;
	}

	&.palestine {
		background:url(./images/palestine.png) center center no-repeat;
		background-size:100%;
	}

	&.zain {
		background:url(./images/zain.png) center center no-repeat;
		background-size:100%;
	}

	&.other {
		background:url(./images/other.png) center center no-repeat;
		background-size:100%;
	}

}

.radiomark {
	content:'';
	display:block;
	width:140px;
	height:140px;
	border:2px solid $border;
	border-radius:50%;
	position:absolute;
	left:-10px;
	top:-10px;
	z-index:5;
}

input:checked ~ .radiomark {
  border:3px solid $green;
}

/** Signup Form **/

#signup-form {
	padding:30px 0;
}